<template>
  <div class="top">
    <div class="img">
      <img src="/static/reimei.png">
      <h1>2021.5.9.Sun<br>150th<br>開成大運動会</h1>
    </div>
    <div class="btn-list">

    <router-link to="/score">
      <button class="btn-score">
        <h2>得点板</h2>
          <p>現在の得点と競技結果をご覧ください</p>
      </button>
    </router-link>

    <router-link to="/events">
      <button class="btn-events">
          <h2>競技紹介</h2>
            <p>各競技のルールをご覧ください</p>
      </button>
    </router-link>

    <router-link to="/intro">
      <button class="btn-intro">
        <h2>組責挨拶</h2>
          <p>各組リーダーの挨拶をご覧ください</p>
      </button>
    </router-link>
  </div>

  <div class="poem">
    <p>今年で開成も150周年を迎え、この開成大運動会を開催できる事を、心よりお慶び申し上げます。<br>一般のお客様はご来場いただけませんが、この熱烈な空気を少しでも感じていただければ幸いです。</p>
  </div>

  <div class="content">
    <div class="info">
      <Title title="開催について" color="fff"/>
      <p>今回の運動会は緊急事態宣言中の開催となる予定ですが、複数の感染症専門家のアドバイスを受け、練習中、競技中のマスクの着用などを徹底して感染症対策に努めております。また、この一年間、運動会練習を行ってきた4月以降も含め、生徒間の感染は生じておりません。詳しくは<a href="https://kaiseigakuen.jp/wp/wp-content/uploads/2021/04/20210424_on_the_sports_day.pdf">こちら</a>をご覧ください。</p>
    </div>
      <Title title="ご挨拶" color="#fff"/>
        <div class="grd-list">
          <Grd name="野水勉" post="校長" img="/static/principal.JPG" msg="2021年開成学園運動会の観戦を楽しみにされていた受験生とその保護者、開成学園OBほか、多くの皆様方へ<br><br> 今年の運動会は、複数の感染症の専門家とも相談した上で、開催の運びとなりましたが、感染拡大の危険性回避のため、無観客での開催となりました。<br> 生徒の保護者・家族は、学園のメールアドレスを通じてインターネット上での観戦ができる予定ですが、無制限に公開することができません。受験生やその保護者、学園OB等、観戦を楽しみにしていた皆様方には、本当に申し訳ありませんが、インターネット観戦もできませんので、何卒ご理解とご容赦をお願い申し上げます。<br> その代わりに、運動会準備委員会からホームページ上で様々な企画を掲載いたしますので、こちらをお楽しみいただければ幸いです。<br><br>開成学園<br>開成中学校・高等学校 校長<br>野水 勉" color="#2486BD"/>
        </div>
      <Title title="NEWS" color="fff"/>
        <div class="tweet">
          <Timeline :id="user_id" sourceType="profile" :options="{ tweetLimit: '3' }"/>
        </div>
    </div>

  </div>

</template>

<script>
  import Grd from '../components/Grd.vue'
  import Title from '../components/Title.vue'
  import {Timeline} from "vue-tweet-embed"

  export default{
    name: 'Top',
    components:{
      Grd,
      Title,
      Timeline,
    },
    data (){
      return {
        user_id: 'kaisei_sportfes'
      }
    }
  }
</script>

<style scoped>
.top{
  background-color: #7AC4E4;
  padding-bottom: 100px;
}
  img{
    position: relative;
    top: 80px;
    left: 20px;
  }
  .img{
    position: relative;
    background-image: url("/static/school.JPG");
    background-size: cover;
    width: 100vw;
    height: 40vw;
    display: block;
  }
  h1{
    display: inline-block;
    font-family: 'Noto Serif JP', serif;
    font-size: 24pt;
    font-weight: 700;
    color: #FFFFFF;
    position: absolute;
    margin-top: auto;
    bottom: 5px;
    right: 10vw;
    text-align: left;
  }
  h2{
    font-family: 'Noto Serif JP', serif;
    font-size: 18px;
    font-weight: 400;
    margin-bottom: 0;
  }
  h4{
    background-color: #fff;
    width: 100px;
  }
  .grd-list{
     padding-bottom: 100px;
  }
  .btn-list{
    display: flex;
    justify-content: center;
  }
  button {
    position: relative;
    display: relative;
    width: 400px;
    height: 90px;
    padding-left: 30px;
    padding-bottom: 0;
    text-align: left;
    border-style: solid;
    background-color: #fff;
    border-collapse: collapse;
    transition:  all .3s;
    overflow: hidden;
    z-index: 2;
  }
  button:hover{
    color: #fff;
  }
  button p{
    margin-top: 0;
    font-size: 14px;
  }
  button::after {
    position: absolute;
    z-index: -1;
    display: block;
    content: '';
    transition:  all .3s;
    width:100%;
    height:100%;
    right: -90%;
    top:0;
  }
  button:hover::after{
    right: 0%;
  }
  .btn-score{
    color: #C52E52;
    border-width: 0 0 5px 0;
    border-color: #C52E52;
  }
  .btn-score::after{
    background-color: #C52E52;
  }
  .btn-events{
    color: #347442;
    border-width: 0 0 5px 0;
    border-color: #347442;
  }
  .btn-events::after{
    background-color: #347442;
  }
  .btn-intro{
    color: #D16C33;
    border-width: 0 0 5px 0;
    border-color: #D16C33;
  }
  .btn-intro::after{
    background-color: #D16C33;
  }
  .poem{
    padding-top: 80px;
    position: relative;
    top: -80px;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 350px;
    overflow: hidden;
    background-image: url("/static/ground.JPG");
    background-repeat: no-repeat;
    background-size: cover;
    background-position: bottom;
    text-align: center;
    vertical-align: middle;
  }
  .poem p{
    width: 70%;
    color: #fff;
  }
  .content{
    max-width: 980px;
    margin-left: auto;
    margin-right: auto;
  }
  .info{
    color: #fff;
    margin-left: auto;
    margin-right: auto;
  }
  .info p{
    width: 80%;
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 80px;
  }
  .tweet{
    width: 300px;
    margin-left: auto;
    margin-right: auto;
  }

  @media screen and (max-width:820px) {
    .btn-list{
      flex-direction: column;
      align-items: center;
    }
    button{
      width: 100vw;
    }
    img{
      height: 150px;
    }
    .img{
      height: 40vh;
        position: relative;
    }
    h1{
      font-size: 18pt;
      right: 10vw;
    }
    .tweet{
      width: auto;
      margin-left: 4%;
      margin-right: 4%;
      padding-bottom: 50px;
    }
  }
  @media (min-width: 1100px){
    .info p{
      width: 900px;
    }
  }

</style>

<style>

</style>
