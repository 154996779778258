<template>
  <div class ="score">
    <Title title="得点板" color="#FD9473"/>
    <ScBoard purple="3 8 8" white="4 7 0" blue="3 9 3" green="5 6 6" orange="4 0 7" yellow="5 8 2" red="3 7 7" black="3 6 2" />

    <Title title="競技結果" color="#FD9473"/>
      <h2>中一学年 馬上鉢巻取り</h2>
      <Winner color="#D16C33" winner="橙組"/>
      <ResultTo5
        play1="緑組 対 黒組" winner1="緑組"
        play2="赤組 対 白組" winner2="白組"
        play3="紫組 対 橙組" winner3="橙組"
        play4="黄組 対 青組" winner4="黄組"
        play5="緑組 対 白組" winner5="白組"
        play6="橙組 対 黄組" winner6="橙組"
        play7="白組 対 橙組" winner7="橙組"
      />

      <h2>中二学年 綱取り</h2>
      <Winner color="#C52E52" winner="赤組"/>
      <ResultTo5
        play1="白組 対 赤組" winner1="赤組"
        play2="黒組 対 青組" winner2="黒組"
        play3="紫組 対 橙組" winner3="紫組"
        play4="黄組 対 緑組" winner4="緑組"
        play5="赤組 対 黒組" winner5="赤組"
        play6="黄組 対 緑組" winner6="緑組"
        play7="赤組 対 緑組" winner7="赤組"
      />

      <h2>中三学年 俵取り</h2>
      <Winner color="#D8C521" winner="黄組"/>
      <ResultTo5
        play1="青組 対 黄組" winner1="黄組"
        play2="紫組 対 白組" winner2="紫組"
        play3="黒組 対 赤組" winner3="赤組"
        play4="橙組 対 緑組" winner4="緑組"
        play5="黄組 対 紫組" winner5="黄組"
        play6="赤組 対 緑組" winner6="緑組"
        play7="黄組 対 緑組" winner7="黄組"
      />

      <h2>高一学年 騎馬戦</h2>
      <Winner color="#242136" winner="黒組"/>
      <ResultTo5
        play1="緑組 対 赤組" winner1="緑組"
        play2="青組 対 白組" winner2="青組"
        play3="紫組 対 黒組" winner3="黒組"
        play4="橙組 対 黄組" winner4="黄組"
        play5="緑組 対 青組" winner5="緑組"
        play6="黒組 対 黄組" winner6="黒組"
        play7="緑組 対 黒組" winner7="黒組"
      />

      <h2>高二学年 棒倒し</h2>
      <Winner color="#8EBDD8" winner="白組"/>
      <ResultTo5
        play1="橙組 対 黒組" winner1="橙組"
        play2="緑組 対 黄組" winner2="黄組"
        play3="白組 対 赤組" winner3="白組"
        play4="青組 対 紫組" winner4="紫組"
        play5="橙組 対 黄組" winner5="黄組"
        play6="白組 対 紫組" winner6="白組"
        play7="黄組 対 白組" winner7="白組"
      />

      <h2>高三学年 棒倒し</h2>
      <Winner color="#D8C521" winner="黄組"/>
      <ResultTo5
        play1="青組 対 橙組" winner1="青組"
        play2="赤組 対 紫組" winner2="紫組"
        play3="黄組 対 緑組" winner3="黄組"
        play4="黒組 対 白組" winner4="黒組"
        play5="青組 対 紫組" winner5="青組"
        play6="黄組 対 黒組" winner6="黄組"
        play7="青組 対 黄組" winner7="黄組"
      />

      <h2>中学個人 学年別リレー</h2>
      <Winner color="#3F8C33" winner="緑組"/>
      <ResultEx
        play1="中一"
        winner11="橙" winner12="白" winner13="青" winner14="緑" winner15="紫" winner16="赤" winner17="黒" winner18="黄"
        play2="中二"
        winner21="緑" winner22="青" winner23="黄" winner24="白" winner25="橙" winner26="黒" winner27="赤"
        play3="中三"
        winner31="紫" winner32="緑" winner33="赤" winner34="橙" winner35="白" winner36="青"
        j="block" h="none"
      />

      <h2>高校個人 スウェーデンリレー</h2>
      <Winner color="#3F8C33" winner="緑組"/>
      <ResultEx
        play1="高一"
        winner11="白" winner12="緑" winner13="赤" winner14="青" winner15="黄" winner16="紫" winner17="橙" winner18=""
        play2="高二"
        winner21="緑" winner22="白" winner23="青" winner24="紫" winner25="黒" winner26="橙" winner27="黄" winner28=""
        play3="高三"
        winner31="緑" winner32="黄" winner33="赤" winner34="紫" winner35="白" winner36="青" winner37="橙" winner38=""
        j="none" h="block"
      />

      <h2>中学個人 要領次第</h2>
      <Winner color="#3F8C33" winner="緑組"/>
      <ResultAll
        winner1="緑" winner2="黄" winner3="白" winner4="橙" winner5="青"      />

  </div>
</template>

<script>
  import Title from '../components/Title.vue'
  import ScBoard from '../components/ScBoard.vue'
  import ResultTo5 from '../components/ResultTo5.vue'
  import ResultEx from '../components/ResultEx.vue'
  import ResultAll from '../components/ResultAll.vue'
  import Winner from '../components/Winner.vue'

  export default{
    name: 'Score',
    components: {
      Title,
      ScBoard,
      ResultTo5,
      ResultEx,
      ResultAll,
      Winner
    }
  }
</script>

<style scoped>
  .score{
    height: 100%;
    background-color: #4696CF;
    padding: 150px 0px;
  }
  p{
    color: #FFF;
    text-align: center;
    margin-bottom: 40px;
  }
  h2{
    font-family: 'Noto Serif JP', serif;
    margin-top: 60px;
    margin-bottom: 10px;
    margin-left:10%;
    font-size: 24px;
    font-weight: 400;
    color: #fff;

  }
  @media screen and (min-width:1100px){
    .score{
      width: 1100px;
      padding-left: calc(50% - 550px);
      padding-right: calc(50% - 550px);
        }
  }
</style>
