<template>
  <div class="close" v-bind:class="{open : isActive}">
    <button v-on:click="Open">詳細</button>
      <table>
      <tr>
        <td rowspan="4">第一試合</td><td class="play">{{play1}}</td><td>{{winner1}}</td>
      </tr>
      <tr>
        <td class="play">{{play2}}</td><td>{{winner2}}</td>
      </tr>
      <tr>
        <td class="play">{{play3}}</td><td>{{winner3}}</td>
      </tr>
      <tr>
        <td class="play">{{play4}}</td><td>{{winner4}}</td>
      </tr>
      <tr>
        <td rowspan="2">第二試合</td><td class="play">{{play5}}</td><td>{{winner5}}</td>
      </tr>
      <tr>
        <td class="play">{{play6}}</td><td>{{winner6}}</td>
      </tr>
      <tr>
        <td>第三試合</td><td class="play">{{play7}}</td><td>{{winner7}}</td>
      </tr>
    </table>
  </div>
</template>

<script>
  export default{
    name: 'ResultTo5',
    props:{
      play1: String,
      winner1: String,
      play2: String,
      winner2: String,
      play3: String,
      winner3: String,
      play4: String,
      winner4: String,
      play5: String,
      winner5: String,
      play6: String,
      winner6: String,
      play7: String,
      winner7: String
    },
    data(){
      return {
        isActive: false
      }
    },
    methods:{
      Open: function(){
        this.isActive = !this.isActive
      }
    }
  }
</script>

<style scoped>
  div{
    transition: 1.0s;
    overflow: hidden;
    margin-left: 10%;
  }
  .close{
    height: 20px;
    overflow: hidden;
  }
  .open{
    height: 300px;
  }
  td{
    padding: 5px 30px;
    vertical-align: top;
  }
  .play{
    border-left: 3px solid #fff;
    border-right: 10px double #fff;
  }
  button{
    position: relative;
    margin-left: 100px;
    height: 20px;
    border-style: none;
    background-color: transparent;
    font-size: 14pt;
    line-height: 18px;
    color: #fff;
    outline: none;
    }
  button::before{
    position: absolute;
    content: '';
    left: -24px;
    width: 0;
    height: 0;
    border-left: 18px solid #fff;
    border-top: 9px solid transparent;
    border-bottom: 9px solid transparent;
    transition: 0.2s;
  }

  .open button::before{
    transform: rotate(90deg);
  }

  table{
      position: relative;
      border-collapse:collapse;
      color: #fff;
      font-size: 14pt;
      margin-left: 100px;
      margin-right: auto;
      overflow: hidden;
      transition: 1.0s;
  }
    @media screen and (max-width:550px) {
      div{
        margin-left: 10px;
      }
      table{
        margin-left: auto;
      }
      td{
        padding: 5px 8px;
      }
      button{
        margin-left: 60px;
      }
    }
</style>
