<template>
  <div class="Grd">
    <h4 v-bind:style="{ 'background-color': color, 'border-color': color}">
      {{name}} <span>{{post}}</span>
    </h4>
    <img v-bind:src="img">
    <p v-html="msg" style="white-space: pre-wrap;"></p>
  </div>
</template>

<script>

  export default{
    name: "Grd",
    props:{
      name: String,
      post: String,
      msg: String,
      color: String,
      img: String
    }
}
</script>

<style scoped>
  .Grd{
    width: 740px;
    display: flex;
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 10px;
    flex-flow: column;
    filter: drop-shadow(rgba(100, 100, 111, 1) 0px 5px 5px);
  }
  img{
    background-color: #ADADAD;
    width: 100%;
  }
  h4{
    box-sizing: border-box;
    position: relative;
    width: calc(100% - 60px);
    height: 60px;
    padding-left: 30px;
    margin: 0 60px 0 0;
    font-size: 20px;
    font-family: "Hiragino Kaku Gothic", sans-serif;
    font-weight: bold;
    color: #fff;
    line-height: 60px;



  }
  h4 span{
    font-size: 14px;
  }
  h4::before{
    content: '';
    position: absolute;
    left: 10px;
    width: 10px;
    height: 60px;
    background-color: #fff;
  }
  h4::after{
    content:'';
    position: absolute;
    right: -60px;
    width:0;
    height:0;
    border-top: 30px solid transparent;
    border-right: 30px solid transparent;
    border-left: 30px solid;
    border-left-color: inherit;
    border-bottom: 30px solid;
    border-bottom-color: inherit;
  }
  p{
    height: auto;
    line-height: 32px;
    padding: 20px;
    background-color: #fff;
    margin: 0;
    font-size: 16px;
  }
  @media screen and (max-width:780px) {
    .Grd{
      width: 90%;
      margin-left: auto;
      margin-right: auto;
    }
    h4 span{
      font-size: 14px;
    }
    p{
      line-height: 24px;
    }
  }
</style>
