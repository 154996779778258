<template>
  <div class ="scboard">
    <table>
      <tr class="purple">
        <td>紫</td><td>{{purple}}</td>
      </tr>
      <tr class="white">
        <td>白</td><td>{{white}}</td>
      </tr>
      <tr class="blue">
        <td>青</td><td>{{blue}}</td>
      </tr>
      <tr class="green">
        <td>緑</td><td>{{green}}</td>
      </tr>
      <tr class="orange">
        <td>橙</td><td>{{orange}}</td>
      </tr>
      <tr class="yellow">
        <td>黄</td><td>{{yellow}}</td>
      </tr>
      <tr class="red">
        <td>赤</td><td>{{red}}</td>
      </tr>
      <tr class="black">
        <td>黒</td><td>{{black}}</td>
      </tr>
    </table>
  </div>
</template>

<script>
  export default {
    name: 'ScBoard',
    props:{
      purple: String,
      white: String,
      blue: String,
      green: String,
      orange: String,
      yellow: String,
      red: String,
      black: String
    }
  }
</script>

<style scoped>
.scboard{
  padding-bottom: 50px;
}
table{
  border-collapse: collapse;
  margin-left: auto;
  margin-right: auto;
  padding-bottom: 50px;
}
  td{
    border: solid 2px #fff;
    padding: 10px 20px 10px 20px;
    color: #fff;
    line-height: 30px;
  }
  .purple{
    background-color: #A055BC;
  }
  .white{
    background-color: #8EBDD8;
  }
  .blue{
    background-color: #3744BC;
  }
  .green{
    background-color: #3F8C33;
  }
  .orange{
    background-color: #D16C33;
  }
  .yellow{
    background-color: #D8C521;
  }
  .red{
    background-color: #C52E52;
  }
  .black{
    background-color: #242136;
  }
  @media screen and (min-width:680px) {
    table{
      display: flex;
      margin: auto;
      text-align: center;
      justify-content: center;
    }
    td{
      display: block;
      margin-left: -2px;
      margin-top: -2px;
    }
  }
</style>
