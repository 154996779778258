import Vue from 'vue'
import VueRouter from 'vue-router'
import Top from '../views/Top.vue'
import Program from '../views/Program.vue'
import Intro from '../views/Intro.vue'
import Score from '../views/Score.vue'
import Events from '../views/Events.vue'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'Top',
    component: Top
  },
  //{
    //path: '/info',
    //name: 'Info',
    //component: () => import('../views/Info.vue')
  //},
  {
    path: '/program',
    name: 'Program',
    component: Program
  },
  {
    path: '/events',
    name: 'Events',
    component: Events
  },
  {
    path: '/score',
    name: 'Score',
    component: Score
  },
  {
    path: '/intro',
    name: 'Intro',
    component: Intro
  }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

export default router
