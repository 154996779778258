<template>
  <div class="winner" v-bind:style="{ 'background-color': color}">
    <p>優勝 {{winner}}</p>
  </div>
</template>

<script>
  export default{
    name: "Winner",
    props:{
      winner: String,
      color: String
    }
  }
</script>

<style scoped>
  .winner{
    display: block;
    width: 100px;
    text-align: center;
    padding: 5px 5px;
    color: #fff;
    border: solid 2px #fff;
    position: relative;
    margin-left: 10%;
    margin-bottom: 10px;
  }
  .winner p{
    font-family:  'Noto Serif JP',serif;
    margin: auto;
  }
  @media screen and (max-width:550px) {
    .winner{
      margin-left: 60px;
    }
  }
</style>
