<template>
  <div class ="events">
    <Title title="競技紹介" color="#fff"/>
    <EveIntro event="・馬上鉢巻取り" intro="馬上鉢巻取りは各組(最大)8 騎まで騎馬を組み、騎乗が互いの鉢巻を取りあう競技です。この競技は、騎乗同士の激しい攻防や、騎馬の圧倒的な押し、それを受け流すローリングの技術が見どころです。騎馬同士 の迫力のある戦いから目が離せない競技になっています。"/>
    <EveIntro event="・綱取り" intro="中2学年は「綱取り」という競技を行います。綱取りとは、1本10mの綱を5本取り合います。綱取りは綱引きと違い、「引く」ことだけでなく、「押す」こともでき、単なる力勝負に留まらず、戦略的で激しい試合展開となります。常に戦況を見て、様々な場所に動く黒いヘッドギアの競技者にもご注目あれ！"/>
    <EveIntro event="・俵取り" intro="俵取りとは米俵に似せた土が入った麻袋（俵）をより多く自陣まで転がすという競技です。<br>俵は100kgを超えるために動かしづらく、展開が地味に見えるかもしれませんが、試合の中でガタイが影響しづらく、高度な戦略や技術、全体を把握した上での状況判断が必要になってくるので「戦略の俵取り」とも呼ばれています。"/>
    <EveIntro event="・騎馬戦" intro="騎馬戦は、4 人または 5 人が騎馬を組んで戦う競技です。中1の馬上鉢巻取りとは異なり、相手の騎乗を地面に触れさせるか、もしくは相手の騎馬をフィールドの外に押し出すと勝利となります。ベッドと呼ばれる、騎馬が騎乗を地面に触れさせないようにする戦術もあり、より複雑で奥の深い競技となっています。"/>
    <EveIntro event="・棒倒し" intro="棒倒しは、5・6 年生が繰り広げる開成大運動会において花形といえる競技です。攻撃は棒を倒しに行き、守備は結束して 棒を守ります。白熱した攻防をぜひご覧ください。高２は「４位制」・高３は「6位制」で順位をつけます。ただし、時間の関係で高３が「４位制 」になる場合もありますので、ご確認下さい。"/>
    <EveIntro event="・要領次第" intro="要領次第は、所謂障害物競走のような競技です。中一は四人一組全員の足を二本の紐で結んで走るムカデ競走、中二はペットボトルを板の上に立て二人で運ぶ出前競走、中三は騎馬を組んで走り、竿で吊るされている紐に襷を結ぶたすき結び競走を行います。中学生のみ参加するということもあり、他の競技とは一味違った内容となっています。"/>
    <EveIntro event="・リレー" intro="◆学年別リレー<br>中学生の各学年4人の選手が1人1周ずつ走ります。<br>◆スウェーデンリレー<br>高校生の各学年 4 人の選手が第 1 走者は 1 周、第 2 走者は 2 周、第 3 走者は 3 周、第 4 走者は 4 周というように走者ごとに周回数を増やして走っていきます。1910 年代のスウェーデンで行われた形式であるのが名前の由来です。周回数が多いためスピードの配分など戦略性も高く逆転も起こりやすい競技です。<br>◆全学年リレー<br>各学年から 1 人ずつ、計 6 人の選手が走り、学年順にバトンを渡していきます。最終競技で得点配分が高く、大変盛り上がります。"/>
  </div>
</template>

<script>
  import Title from '../components/Title.vue'
  import EveIntro from '../components/EveIntro.vue'

  export  default{
    name: 'Events',
    components:{
      Title,
      EveIntro
    }

  }
</script>

<style scoped>
.events{
  padding-top: 100px;
  padding-bottom: 20px;
  background-color: #7AC4E4;
}
</style>
