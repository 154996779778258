<template>
  <div class="close" v-bind:class="{open : isActive}">
    <button v-on:click="Open">詳細</button>
      <table>
      <tr class="top">
        <td>一着</td><td>二着</td><td>三着</td><td>四着</td><td>五着</td><td>六着</td><td>七着</td><td>八着</td>
      </tr>
      <tr>
        <td>{{winner1}}</td><td>{{winner2}}</td><td>{{winner3}}</td><td>{{winner4}}</td><td>{{winner5}}</td><td>{{winner6}}</td><td>{{winner7}}</td><td>{{winner8}}</td>
      </tr>
    </table>
    <p>なお、紫組、赤組、黒組は失格となりました。</p>
  </div>
</template>

<script>
  export default{
    name: 'ResultAll',
    props:{
      play1: String,
      winner1: String,
      winner2: String,
      winner3: String,
      winner4: String,
      winner5: String,
      winner6: String,
      winner7: String,
      winner8: String,
    },
    data(){
      return {
        isActive: false
      }
    },
    methods:{
      Open: function(){
        this.isActive = !this.isActive
      }
    }
  }
</script>

<style scoped>
  div{
    transition: 1.0s;
    overflow: hidden;
    margin-left: 10%;
  }
  .close{
    height: 20px;
    overflow: hidden;
  }
  .open{
    height: 250px;
  }
  td{
    padding: 5px 30px;
  }
  .top{
    border-bottom: 3px solid #fff
  }
  .play{
    border-right: 10px double #fff;
  }
  p{
    text-align: center;
    color: #fff;
    margin-left: 100px;
    margin-right: auto;
  }
  button{
    position: relative;
    margin-left: 100px;
    height: 20px;
    border-style: none;
    background-color: transparent;
    font-size: 14pt;
    line-height: 18px;
    color: #fff;
    outline: none;
    }
  button::before{
    position: absolute;
    left: -24px;
    content: '';
    width: 0;
    height: 0;
    border-left: 18px solid #fff;
    border-top: 9px solid transparent;
    border-bottom: 9px solid transparent;
    transition: 0.2s;
  }

  .open button::before{
    transform: rotate(90deg);
  }

  table{
      position: relative;
      border-collapse:collapse;
      color: #fff;
      font-size: 14pt;
      margin-top:30px;
      margin-left: 100px;
      margin-right: auto;
      overflow: hidden;
      transition: 1.0s;
  }
  @media screen and (min-width:970px) {
    .open{
      height: 200px;
    }
  }
  @media screen and (max-width:550px) {
    div{
      margin-left: 10px;
    }
    p{
      margin-left: auto;
    }
    table{
      margin-left: auto;
    }
    td{
      padding: 5px 8px;
    }
    button{
      margin-left: 60px;
    }
  }
</style>
