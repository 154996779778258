<template>
  <div class="EveIntro">
    <h3>{{event}}</h3>
    <img v-bind:src="img_src"/>
    <p v-html="intro" style="white-space: pre-wrap;"></p>
  </div>
</template>

<script>

 export default{
   name: 'EveIntro',
   props:{
     event: String,
     intro: String,
     img_src: String
   }
 }


</script>

<style scoped>
  .EveIntro{
    display: block;
    position: relative;
    width: 80%;
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 60px;
    background-color: white;
    border-radius: 10px;
  }
  img{
    display: block;
    background-color: #FD9473;
    width: 60%;
    margin-right: auto;
    margin-left: auto;
  }
  h3{
    position: relative;
    font-family: 'Noto Serif JP', serif;
    font-weight: 300;
    padding-top: 30px;
    padding-left: 20px;
    margin-bottom: 10px;
    font-size: 20px;
  }
  p{
    padding: 20px;
    margin: 0;
    line-height: 32px;
  }
</style>
