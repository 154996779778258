<template>
  <div class="Intro">

    <Title title="組責挨拶" color="#fff"/>
    <div class="container">
      <Grd name="第六十五代紫組組責" post="" msg="私自身、中一から中三まで一度も勝つことができず、高一は準優勝で終わってしまいました。ただ、それぞれの運動会で得られる経験はどれもかけがえのないものです。その思いを胸に高三、組責として組を引っ張っていきます。<br>今年の運動会を開催するにあたり、安全性を十分に確保した上で運動会を通じて得られるものを下級生に伝えていくために、高三は半年以上も前から準備を進めてきました。そして今、下級生とも一丸となって本番に向けて動いています。<br>一昨年総合優勝を達成した組として、今年の目標は全競技優勝をしての完全優勝です。運動会、そして紫組の成功のためにも応援よろしくお願いします。" color="#A055BC"/>
      <!--<div class="arch">
        <p>紫組アーチ</p>
        <img alt="紫組アーチ">
      </div>
      <Player team="紫" color="#664576"/>-->
    </div>

    <div class="container">
      <Grd name="第七十六代白組組責" post="" msg="早くも昼間は汗ばむ季節になってまいりました。<br>下級生指導もなんとか始まり本気で指導する高三と必死についていこうとする後輩の姿を一年ぶりに見ることができ大変嬉しく思います。<br>厳しい社会情勢の中この様な活動ができるのはコロナ禍においても、生徒の自主性を重んじる伝統を理解してくださっている先生方並びに保護者の方々の御協力あってのことです。<br>深く御礼申し上げます。<br>運動会の楽しさを後輩たちに伝え、未来への不安をも払える様な素晴らしい体験をさせていきたいと思っております。<br>残念ながら当日ご来場いただくことは叶いませんが、ぜひ運動会を通して成長していく姿をご覧ください。" color="#8EBDD8"/>
      <!--<div class="arch">
        <p>白組アーチ</p>
        <img alt="白組アーチ">
      </div>
      <Player team="白" color="#99CFEE"/>-->
    </div>

    <div class="container">
      <Grd name="第七十六代青組組責" post="" msg="ここ1年は伝統に対して疑いの目をかけることの多かったものでした。しかし結局、運動会は長年の伝統を絶やさないために今年はとにかく開催することに尽力しようという事になりました。どうして伝統を絶やしたがらないのでしょう。それはきっと運動会がとても楽しかった思い出として開成生の多くに刻まれるからです。運動会は楽しいからやってると言う訳なんですね。<br>実際僕は運動会は楽しいことが1番だと今でも強く考えています。運動会から楽しみが消えた時、運動会は消滅するでしょう。だからこそ、開催するからには絶対に楽しむことが必要です。参加する人はとにかく、運動会を楽しめる心とその余裕を大切にしてほしいです。そしてもし楽しむ余裕がなくすなら、それはもういい伝統にはならないと、そう感じるも大切だと思います。<br>これは僕の運動会観です。<br><br>もし今年運動会が出来たらそれは本当に偉業です。開催に尽力してくれた同級生達や携わってくださった皆さんのおかげです。本当にただただ感謝がとまりません。" color="#3744BC"/>
      <!--<div class="arch">
        <p>青組アーチ</p>
        <img alt="青組アーチ">
      </div>
      <Player team="青" color="#4754CB"/>-->
    </div>

    <div class="container">
      <Grd name="第七十六代緑組組責" post="" msg=" 開成の運動会の準備が始まるのは前年の運動会が終わった瞬間である。そこから次の4月の下級生練習開始までに優勝のための研究を進める。<br> 競技する組だけではない。運動会準備委員会、審判団、審議会、記録委員会。その全ての組織が5月第二日曜ただ1日のために一年間準備を重ねる。<br>開成の運動会では自分の競技で負けた者は皆涙する。その涙こそが開成生の運動会にかける思いであり、次年度に向けた原動力であるのだ。<br>昨年度の運動会は中止になった。今年は二年分の思いが詰まった歴史的な運動会だ。<br>涙の上に築かれた93回目の運動会は僕らに何を魅せてくれるだろうか。" color="#3F8C33"/>
      <!--<div class="arch">
        <p>緑組アーチ</p>
        <img alt="緑組アーチ">
      </div>
      <Player team="緑" color="#3F8C33"/>-->
    </div>

    <div class="container">
      <Grd name="第六十代橙組組責" post="" msg="桜が散ったのももう前のことで、夏の近づきを感じることが多くなりました。<br>新学期の忙しない雰囲気に懐かしさを感じ、グラウンドに散る８色のTシャツと下級生の姿に運動会の存在を実感しております。<br>新型コロナウイルスの影響で制限の多い運動会だからこそ下級生には全力で運動会に臨み、堪能し得る限りを味わって欲しいと思います。<br>昨年度の中止の影響もあり、思うようにいかないことだらけですが、立ち止まった分力強い確実な一歩を踏み出す為にも生徒達の熱量と、それを支える保護者の皆様や先生方のご協力を引き続き宜しくお願い致します。<br>運動会が無事開催されることを願って。" color="#D16C33"/>
      <!--<div class="arch">
        <p>橙組アーチ</p>
        <img alt="橙組アーチ">
      </div>
      <Player team="橙" color="#D16C33"/>-->
    </div>

    <div class="container">
      <Grd name="第七十六代黄組組責" post="" msg="改めて問う。君は運動会を楽しめているか？<br>異例ばかりの運動会。始動は遅れ、作業は増え、考慮すべきことも例年の比ではなく多かった。それでも高3は考え続け、下級生はそれに呼応して成長した。君はその過程で何を学んだだろうか。<br>昨年の中止という過去は変えられない。僕達にできるのは、運動会という誇り高き伝統を絶やすことなく、後世へつなげることに他ならない。その為に、今この一瞬で君は何ができるか。何を吸収するか。すべては構成員である君次第だ。<br>組責任者として、君が輝くための道を作ってきた。足を踏み外して危険に陥らないよう、道を強固にしてきた。さあ、次は君の番だ。誰よりも楽しめ。今しか味わえないことを、楽しめ。<br>黄色に、いや君色に輝く姿を見せてくれ。" color="#D8C521"/>
      <!--<div class="arch">
        <p>黄組アーチ</p>
        <img alt="黄組アーチ">
      </div>
      <Player team="黄" color="#E0C015"/>-->
    </div>

    <div class="container">
      <Grd name="第七十六代赤組組責" post="" msg="中止の決定に打ち拉がれた昨年の運動会から1年。<br>紆余曲折を経ながらも本番まで辿り着いた。<br>常に中止と隣合わせの不安に苛まれながらも、皆それぞれが信念をもって運動会に関わってきた。<br>このご時世にこれほど激しい行事をするに至れたのは、関わった全ての人の情熱と開催への揺るぎない決意が根底にあったからだろう。<br>今こそ皆の思いをこの日この一瞬に昇華させ、｢150th運動会｣を創り出すとき。<br>それは一度きりのものだからこそ強い輝きを放つとともに、脈々と受け継がれてきた運動会の復興を示すものになるだろう。<br>そしていつか振り返ったとき、この運動会を開催して良かった、そう思えるような運動会になることを願っている。" color="#C52E52"/>
      <!--<div class="arch">
        <p>赤組アーチ</p>
        <img alt="赤組アーチ">
      </div>
    <Player team="赤" color="#C52E52"/>-->
  </div>

    <div class="container">
      <Grd name="第四十六代黒組組責" post="" msg="今年はコロナ禍での運動会となり、それに伴って様々な制約の中で運動会活動を行うこととなりました。<br>このような厳しい状況の中でも、昨年度の運動会中止によって一度は消えかけた「運動会の伝統」という火を繋ぐために、我々高三を中心に生徒一丸となって開催に向けた準備をして参りました。<br>運動会当日を目前に控えた今、運動会にかける思いは最高潮に達しています。<br>当日、我々の運動会への熱い情熱で、この火が真っ赤に燃え上がるところを是非見届けて頂きたいです。よろしくお願いします。" color="#242136"/>
        <!--<div class="arch">
        <p>黒組アーチ</p>
        <img alt="黒組アーチ">
      </div>-->
      <!--<Player team="黒" color="#242136"/>-->
    </div>



  </div>
</template>

<style scoped>
  .Intro{
    background-color: #7AC4E4;
    padding-top: 100px;
    width: 100%;
  }
  .container{
    display: flex;
     justify-content: space-around;
     align-items: center;
     padding-bottom: 100px;
  }
  .arch{
    width: 300px;
    height: 300px;
  }
  img{
    width: 200px;
    height: 200px;
    background-color: #ADADAD;
  }
    @media screen and (max-width:780px) {
      .container{
        flex-flow: column;
      }
    }
</style>

<script>
  import Title from '../components/Title.vue'
  import Grd from '../components/Grd.vue'

  export default{
    name: 'Intro',
    components:{
      Title,
      Grd
    },
    data (){
    return {
      msg: window.ScrollReveal().reveal('.Grd')
     }
    }
  }
</script>
