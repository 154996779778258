<template>

  <div id="app">

    <ul id="navPc">
      <img src="static/penken.png">
      <li><router-link to="/">Top</router-link></li>
      <li><router-link to="/program">プログラム</router-link></li>
      <li><router-link to="/events">競技紹介</router-link></li>
      <li><router-link to="/score">得点板</router-link></li>
      <li><router-link to="/intro">組責挨拶</router-link></li>
    </ul>

    <nav id="navSp" v-bind:class="{open : isActive}">
      <ul id="menu">
        <li><router-link to="/">Top</router-link></li>
        <li><router-link to="/program">プログラム</router-link></li>
        <li><router-link to="/events">競技紹介</router-link></li>
        <li><router-link to="/score">得点板</router-link></li>
        <li><router-link to="/intro">組責挨拶</router-link></li>
      </ul>

      <img src="static/penken.png">
        <button v-on:click="Change" v-bind:class="{open : isActive}">
          <svg width="30" height="21" viewBox="0 0 30 21" fill="none" xmlns="http://www.w3.org/2000/svg">
          <g id="Group 5">
          <rect id="bar1" width="30" height="1" fill="black"/>
          <path id="bar2" d="M0 10H30V11H0V10Z" fill="black"/>
          <rect id="bar3" y="20" width="30" height="1" fill="black"/>
          </g>
          </svg>
        </button>
    </nav>

    <router-view/>

    <footer>
      <div id="btn-box">
        <button class="twitter" onclick="window.open('https://twitter.com/kaisei_sportfes')"><img src ="/static/Twitter_icon.svg"></button>
        <button class="instagram" onclick="window.open('https://www.instagram.com/kaisei_sportfes/')"><img src ="/static/Instagram_icon.svg"></button>
        <button class="facebook" onclick="window.open('https://www.facebook.com/%E9%96%8B%E6%88%90%E5%AD%A6%E5%9C%92%E5%A4%A7150th%E9%81%8B%E5%8B%95%E4%BC%9A-2268646819860340/')"><img src ="/static/Facebook_icon.svg"></button>
      </div>
      <p>2021 150th Kaisei-Sports-Festival</P>
    </footer>
  </div>
</template>

<script>
  export default{
    name: 'App',

    data(){
      return{
        isActive: false
      }
    },
    methods:{
      Change: function(){
        this.isActive = !this.isActive
      }
    },
    watch: {
      '$route': function (to, from) {
          if (to.path !== from.path) {
            this.isActive = !this.isActive
          }
        }
      }
    }
</script>

<style>

html,body,button{
  font-family: "Hiragino Kaku Gothic","游ゴシック Medium","Yu Gothic Medium","游ゴシック","Yu Gothic","游ゴシック体", "meiryo", sans-serif;
  font-size: 16px;
}
body{
  margin: 0;
}

#app {
  box-sizing: border-box;
}


#navSp{
  display: none;
}
#navPc {
  display: flex;
  width: 100%;
  padding: 5px 20px 5px 3px;
  margin: 0;
  position: fixed;
  top: 0;
  justify-content: center;
  align-items: center;
  list-style: none;
  z-index: 5;
  background: rgba(255,255,255, 0.9);
}
#navPc li{
  list-style: none;

  margin: 0 auto;
  text-align: center;



}
#navPc img{
  width: 60px;
  padding: 10px;
  height: auto;
  margin-right: auto;
}

#navPc a {
  font-family:  'Noto Serif JP',serif;
  font-weight: 400;
  font-size: 14px;
  text-decoration: none;
  color: #1B1B1B;
}

#navPc a.router-link-exact-active {
  color: #ADADAD;
}

footer{
  position: relative;
  display: block;
  background-color: #D16C33;
  width: 100%;
  height: 170px;
}
footer p{
  position: absolute;
  display: inline;
  bottom: 0;
  color: #000;
  right: 10px;
}
#btn-box{
  position: relative;
  margin-left: auto;
  margin-right: auto;
  top: 30px;
  display: flex;
  justify-content: space-around;
  width: 300px;
}
#btn-box button{
  text-align: center;
  justify-content: center;
  border-style: none;
  outline: none;
  background-color: #fff;
  z-index: 1;
  -webkit-border-radius: 40px;
  -moz-border-radius: 40px;
  border-radius: 40px;
  width: 80px;
  height: 80px;
  filter:drop-shadow(rgba(0, 0, 0, 0.3) 5px 5px 3px);
}
#btn-box button img{
  position: relative;
  margin: auto;
  width: 60%;
  height: 60%;
}
  @media screen and (max-width:780px){
    #navPc{
      display: none;
    }
    #navSp{
      display: block;
      position: fixed;
      z-index: 3;
      top: 0;
      background-color: #fff;
      width: 100%;
    }
    #navSp li{
      list-style: none;
      margin: 0 auto;
      text-align: center;
    }
    #navSp a {
      font-size: 18px;
      font-family: 'Noto Serif JP', serif;
      font-weight: 400;
      text-decoration: none;
      color: #1B1B1B;
    }
    #navSp img{
      width: 70px;
      padding: 5px;
      height: auto;
      margin-right: auto;
    }
    #navSp button{
      position: absolute;
      right: 20px;
      top: 20px;
      border-style: none;
      background-color: transparent;
      outline: none;
      padding: 0;
    }
    #menu{
      width: 100%;
      height: 80%;
      padding: 0;
      display: flex;
      flex-flow: column;
      justify-content: space-around;
      background-color: #fff;
      position: fixed;
      top: -120%;
      transition: 0.5s;
      z-index: -1;
    }
    .open #menu{
      top: 20px;
    }
    #bar1, #bar2, #bar3 {
     transition: all 0.3s ease;
    }
    .open #bar1{
      transform-origin: left top;
      transform: translate(0px, 0px) rotate(45deg);
    }
    .open #bar2{
      opacity: 0;
    }
    .open #bar3{
      transform-origin: left bottom;
      transform: translate(0px, 0px) rotate(-45deg);
    }



    #navSp a.router-link-exact-active {
      color: #ADADAD;
    }
  }
</style>
