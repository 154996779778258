<template>
  <h3 v-bind:style ="{'border-color': color}">{{title}}</h3>
</template>

<script>
  export default{
    name: 'Title',
    props:{
      title: String,
      color: String
    }
  }
</script>

<style scoped>
 h3{
    font-weight: bold;
    font-size: 24px;
    color: #fff;
    width: 90%;
    margin: 0 auto 50px auto;
    padding-left: 5px;
    padding-top: 10px;
    border-left: 6px solid;
    border-bottom: 5px solid;
  }
  h3::before{
    content: '';
    height: 8px;
    width: 100px;
    margin-right: 10px;
    border-left: 5px solid;
    border-color: inherit;
  }
  @media (min-width: 1100px){
    .program{
      padding-left: calc(100% - 1100px);
      padding-left: calc(100% - 1100px);
       }
  }


</style>
