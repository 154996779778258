<template>
  <div class="close" v-bind:class="{open : isActive}">
    <button v-on:click="Open">詳細</button>
      <table>
      <tr class="top">
        <td class="play"></td><td>一着</td><td>二着</td><td>三着</td><td>四着</td><td>五着</td><td>六着</td><td>七着</td><td>八着</td>
      </tr>
      <tr>
        <td class="play">{{play1}}</td><td>{{winner11}}</td><td>{{winner12}}</td><td>{{winner13}}</td><td>{{winner14}}</td><td>{{winner15}}</td><td>{{winner16}}</td><td>{{winner17}}</td><td>{{winner18}}</td>
      </tr>
      <tr>
        <td class="play">{{play2}}</td><td>{{winner21}}</td><td>{{winner22}}</td><td>{{winner23}}</td><td>{{winner24}}</td><td>{{winner25}}</td><td>{{winner26}}</td><td>{{winner27}}</td><td>{{winner28}}</td>
      </tr>
      <tr>
        <td class="play">{{play3}}</td><td>{{winner31}}</td><td>{{winner32}}</td><td>{{winner33}}</td><td>{{winner34}}</td><td>{{winner35}}</td><td>{{winner36}}</td><td>{{winner37}}</td><td>{{winner38}}</td>
      </tr>
      </table>
      <p v-bind:style="{'display' : j}"> なお、中学2年生の紫組、中学3年生の黒組、黄組は失格となりました。</P>
      <p v-bind:style="{'display' : h}">なお、高校1年生の黒組、高校2年生の赤組、高校3年生の黒組は失格となりました。</P>
  </div>
</template>

<script>
  export default{
    name: 'ResultEx',
    props:{
      play1: String,
      winner11: String,
      winner12: String,
      winner13: String,
      winner14: String,
      winner15: String,
      winner16: String,
      winner17: String,
      winner18: String,
      play2: String,
      winner21: String,
      winner22: String,
      winner23: String,
      winner24: String,
      winner25: String,
      winner26: String,
      winner27: String,
      winner28: String,
      play3: String,
      winner31: String,
      winner32: String,
      winner33: String,
      winner34: String,
      winner35: String,
      winner36: String,
      winner37: String,
      winner38: String,
      j: String,
      h: String
    },
    data(){
      return {
        isActive: false
      }
    },
    methods:{
      Open: function(){
        this.isActive = !this.isActive
      }
    }
  }
</script>

<style scoped>
  div{
    transition: 1.0s;
    overflow: hidden;
    margin-left: 10%;
  }
  .close{
    height: 20px;
    overflow: hidden;
  }
  .open{
    height: 400px;
  }
  td{
    padding: 5px 30px;
  }
  .top{
    border-bottom: 3px solid #fff;
  }
  .play{
    border-right: 10px double #fff;
  }
  button{
    position: relative;
    margin-left: 100px;
    height: 20px;
    border-style: none;
    background-color: transparent;
    font-size: 14pt;
    line-height: 18px;
    color: #fff;
    outline: none;
    }
  button::before{
    position: absolute;
    left: -24px;
    content: '';
    width: 0;
    height: 0;
    border-left: 18px solid #fff;
    border-top: 9px solid transparent;
    border-bottom: 9px solid transparent;
    transition: 0.2s;
  }

  .open button::before{
    transform: rotate(90deg);
  }

  table{
      position: relative;
      border-collapse:collapse;
      color: #fff;
      font-size: 14pt;
      margin-top: 30px;
      margin-left: 100px;
      margin-right: auto;
      overflow: hidden;
      transition: 1.0s;
  }
  p{
    text-align: center;
    color: #fff;
    margin-left: 100px;
    margin-right: auto;
  }
  @media screen and (min-width:1100px) {
    .open{
      height: 260px;
    }
  }
  @media screen and (max-width:900px) {
    div{
      margin-left: 10px;
    }
    table{
      margin-left: auto;
    }
    p{
      margin-left: auto;
    }
    td{
      padding: 5px 8px;
      text-align: center;
    }
    button{
      margin-left: 60px;
    }
  }
  @media screen and (max-width:630px){
    p{
      text-align: left;
      margin: 0 5px;
    }
  }
</style>
